import React from "react"
import { graphql, Link } from "gatsby";
import Layout from "../../components/Layout"
import { getImage } from "gatsby-plugin-image"
import {
  CardBody,
  CardDetail,
  CardImage,
  CardSubTitle,
  CardTitle,
} from "../../styles/Global.styled"

const ReleaseTemplate = ({
  data: {
    release,
    tracks: { nodes: tracks },
  },
}) => {
  // const releases = data.releases
  if (!release) {
    // Some pages are auto generated that are not markdown, but they are still generated... so return null
    return null
  }
  if (release.frontmatter.type !== "release") {
    // Some pages are auto generated that are markdown but not tracks, but they are still generated... so return null
    return null
  }
  const title = release.frontmatter.title
  const releaseDate = new Date(release.frontmatter.releaseDate)
  const trackList = release.frontmatter.tracks
  const genres = release.frontmatter.genres
  const spotifyLink = release.frontmatter.spotifyEmbed.code || ""
  const bandCampLink = release.frontmatter.bandCampEmbed.code || ""
  // const soundCloudLink = release.frontmatter.soundCloudEmbed.code || ""
  const soundCloudLink = ""

  const durationTotal = new Date(0)
  let durationString = "0"
  // console.log("tracks in release:")
  // console.log(tracks)

  return (
    <Layout title={title}>
      <CardDetail>
        <CardTitle>{title}</CardTitle>
        <CardSubTitle>
          <p>Released: {releaseDate.toDateString()}</p>
          <p>Release Type: {release.frontmatter.releaseType}</p>
          <p>Track List</p>
          <ol>
            {trackList.map(trackTitle => {
              const track = tracks.find(track => {
                return track.frontmatter.title === trackTitle
              })
              if (!track.frontmatter) {
                return <li>Unknown</li>
              }
              const trackPath = `/tracks${track.fields.slug}`
              const duration = new Date(0)
              duration.setTime(track.frontmatter.duration * 1000) // set the absolute millisecond time
              durationString =
                duration.getMinutes() +
                ":" +
                duration.getSeconds().toString().padStart(2, "0")
              durationTotal.setTime(durationTotal.getTime() + duration.getTime())

              return (
                <li>
                  <Link to={trackPath}>{trackTitle}</Link> - {durationString}
                </li>
              )
            })}
          </ol>
          <p>
            Total play time:{" "}
            {durationTotal.getMinutes() +
              ":" +
              durationTotal.getSeconds().toString().padStart(2, "0")}
          </p>
        </CardSubTitle>
        <CardBody>
          <b>Track Notes:</b>
          <p dangerouslySetInnerHTML={{ __html: release.html }} />
          <b>Listen Online:</b>
          <p style={{maxWidth: "24rem"}} dangerouslySetInnerHTML={{ __html: spotifyLink }} />
          <p style={{maxWidth: "16rem"}} dangerouslySetInnerHTML={{ __html: bandCampLink }} />
          <p style={{maxWidth: "16rem"}} dangerouslySetInnerHTML={{ __html: soundCloudLink }} />
        </CardBody>
        <CardImage
          alt="cover art"
          image={getImage(release.frontmatter.cover)}
        />
      </CardDetail>
    </Layout>
  )
}

export default ReleaseTemplate

export const trackQuery = graphql`
  query getReleaseByFieldSlug($fields__slug: String) {
    release: markdownRemark(
      fields: { slug: { eq: $fields__slug } }
      frontmatter: { type: { eq: "release" } }
    ) {
      fields {
        slug
      }
      frontmatter {
        releaseDate
        releaseType
        title
        type
        tracks
        cover {
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG, width: 350, formats: AUTO)
          }
        }
        author
        genres
        spotifyEmbed {
          code
        }
        bandCampEmbed {
          code
        }
      }
      id
      html
    }
    tracks: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "track" } } }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          duration
        }
      }
    }
  }
`
